import "./ContactForm.scss";

import React from "react";
import { withPrefix } from "gatsby";
import axios from "axios";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-textarea-autosize";
import { CSSTransition, SwitchTransition } from "react-transition-group";

import Subrayado1 from "../../svg/subrayados/subrayado01.svg";
import Separador from "../../svg/lineaSeparador.svg";

const FORM_ENDPOINT = "";

export default function ContactForm() {
	const { t } = useTranslation();
	const [loading, setLoading] = React.useState(false);
	const [toSend, setToSend] = React.useState({
		from_email: "",
		name: "",
		comment: "",
		section: "Contacto",
		data_protection: "No",
		com_comercial: "No",
	});

	const [formSent, setFormSent] = React.useState(false);
	const [formError, setFormError] = React.useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();

		setFormError(false);
		setLoading(true);

		axios({
			method: "post",
			// url: "https://peim.es/php_test/begin/envioContacto.php", // for testing purposes
			url: withPrefix("/php/envioContacto.php"),

			data: {
				...toSend,
			},
		})
			.then((res) => {
				setLoading(false);

				setFormSent(true);
				// console.log(res);
			})
			.catch((err) => {
				setLoading(false);
				setFormError(true);
				console.log(err);
			});
	};

	const handleChange = (e) => {
		if (e.target.type === "checkbox") {
			return setToSend((prevState) => {
				return {
					...prevState,
					[e.target.name]: e.target.checked
						? "Si. Fecha: " +
						  new Date() +
						  ". Texto: " +
						  e.target?.labels[0]?.innerText
						: "No",
				};
			});
		}
		return setToSend((prevState) => {
			return { ...prevState, [e.target.name]: e.target.value };
		});
	};

	return (
		<div>
			<SwitchTransition mode="out-in">
				<CSSTransition key={formSent} timeout={400} classNames="fade">
					<>
						{!formSent ? (
							<div>
								<form
									action={FORM_ENDPOINT}
									onSubmit={handleSubmit}
									onChange={handleChange}
									method="POST"
									target="_blank"
									className="contactForm"
									id="contactoForm"
								>
									<div>
										{/* <label htmlFor="name">
							<p>
								<Trans>Nombre y apellidos</Trans>:
							</p>
						</label> */}
										<input
											type="text"
											placeholder={t("Nombre")}
											id="name"
											name="name"
											title=" "
											required
										/>
										<Separador />
									</div>

									<div>
										{/* <label htmlFor="from_email">
							<p>
								<Trans>Déjanos tu email</Trans>:
							</p>
						</label> */}
										<input
											type="email"
											placeholder={t("Email")}
											id="from_email"
											name="from_email"
											title=" "
											required
										/>
										<Separador />
									</div>

									<div>
										<TextareaAutosize
											placeholder={t("Mensaje")}
											id="comment"
											name="comment"
											title=" "
											required
											value={toSend.comment}
											maxRows={8}
											minRows={1}
										/>
										<Separador />
									</div>

									<div className="dataProtection">
										<div className="dataCheck">
											<input
												type="checkbox"
												name="data_protection"
												id="data_protection"
												required
											/>
											<label
												htmlFor="data_protection"
												aria-label={t(
													"Consiento el uso de mis datos personales para que atiendan mi solicitud"
												)}
											>
												<p>
													<Trans ns="dataProtection">
														Consiento el uso de mis datos personales para que
														atiendan mi solicitud, según lo establecido en su{" "}
														<a
															href="/legal/politica-privacidad"
															target="_blank"
															rel="noopener noreferrer"
														>
															<Trans ns="dataProtection">
																Política de Privacidad
															</Trans>
														</a>{" "}
														y declaro tener cumplidos los catorce (14) años de
														edad, asumiendo las posibles responsabilidades
														legales sobre la veracidad de esta declaración.
													</Trans>
												</p>
											</label>
										</div>

										<div className="dataCheck">
											<input
												type="checkbox"
												name="com_comercial"
												id="com_comercial"
											/>
											<label
												htmlFor="com_comercial"
												aria-label={t(
													"Consiento la recepción de comunicaciones del restaurante por e-mail y/o SMS con fines comerciales"
												)}
											>
												<p>
													<Trans ns="dataProtection">
														Consiento la recepción de comunicaciones del
														restaurante por e-mail y/o SMS con fines comerciales
													</Trans>
												</p>
											</label>
										</div>
									</div>

									<div className="formSubmit">
										{loading && !formError ? (
											<p>Enviando...</p>
										) : (
											<>
												<button type="submit" aria-label={t("Enviar")}>
													<h3>
														<Subrayado1 className="subrayado" />
														<Trans>Enviar</Trans>
													</h3>
												</button>
												{formError && (
													<div>
														<p>
															<Trans>
																¡Ups! Ha habido un error en el envío del
																mensaje. ¡Lo sentimos!
															</Trans>
														</p>
														<p>
															<Trans>
																Vuelve a intentarlo en unos momentos. Si el
																problema persiste, también puedes contactarnos a
																través del chat o de nuestras Redes Sociales:
															</Trans>
														</p>
													</div>
												)}
											</>
										)}
									</div>
								</form>
							</div>
						) : (
							<div>
								<p>
									<Trans>
										Solicitud recibida. Pronto nos pondremos en contacto
										contigo.
									</Trans>
								</p>
							</div>
						)}
					</>
				</CSSTransition>
			</SwitchTransition>
		</div>
	);
}
