import "./contacto.scss";

import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { graphql } from "gatsby";

import Layout from "../../components/layout/Layout";
import Seo from "../../components/seo";
import { Link } from "gatsby-plugin-react-i18next";
import ContactForm from "../../components/contact/ContactForm";

export default function IndexPage() {
	const { t } = useTranslation();

	return (
		<Layout
			footerText={t("¿Cómo se llama este capítulo de tu vida?")}
			className="invertColors"
		>
			<Seo title={t("Contacto")} />

			<div id="contacto" className="section invertColors">
				<div className="tituloContacto">
					<h1 className="lowercase">
						<Trans i18nKey="titleContacto">
							Hola, <i>¿qué tal?</i>
						</Trans>
					</h1>
				</div>

				<div className="contactoBody">
					<div>
						<p className="contactoText juana">
							<Trans>
								Somos Begin, un proyecto que trabaja en construir un futuro más
								conectado con la naturaleza a través de comida de Origen y
								experiencias de conexión únicas.
							</Trans>
						</p>
						<p className="contactoText juana">
							<Trans>
								Imaginamos que tienes alguna duda, quieres aportar algo para que
								podamos mejorar o sencillamente quieres comentarnos cualquier
								otra cosa. Sea lo que sea, estamos encantados de escucharte.
							</Trans>
						</p>
						<p>
							<Trans>
								También puedes consultar nuestro apartado de{" "}
								<Link to="/legal/FAQ/">preguntas frecuentes</Link>
							</Trans>
						</p>
					</div>
					<div>
						<ContactForm />
					</div>
				</div>

				<div className="dataProtection">
					<p>
						<Trans ns="dataProtection">
							Información básica sobre protección de datos
						</Trans>
						:
					</p>
					<table>
						<tbody>
							<tr>
								<td>
									<Trans ns="dataProtection">Responsable</Trans>:
								</td>
								<td>BEGIN RESTAURANTES, S.L.</td>
							</tr>
							<tr>
								<td>
									<Trans ns="dataProtection">Domicilio</Trans>:
								</td>
								<td>
									Av. Cortes Valencianas, 50, CP 46015 Valencia (Valencia)
								</td>
							</tr>
							<tr>
								<td>
									<Trans ns="dataProtection">Finalidad</Trans>:
								</td>
								<td>
									<Trans ns="dataProtection">
										Atender su solicitud de información
									</Trans>
									.
								</td>
							</tr>
							<tr>
								<td>
									<Trans ns="dataProtection">Legitimacion</Trans>
								</td>
								<td>
									<Trans ns="dataProtection">
										Sus datos serán tratados solo con su consentimiento, al
										marcar la casilla mostrada en este formulario
									</Trans>
									.
								</td>
							</tr>
							<tr>
								<td>
									<Trans ns="dataProtection">Destinatarios</Trans>:
								</td>
								<td>
									<Trans ns="dataProtection">
										Sus datos no serán cedidos a terceros
									</Trans>
									.
								</td>
							</tr>
							<tr>
								<td>
									<Trans ns="dataProtection">Derechos</Trans>:
								</td>
								<td>
									<Trans ns="dataProtection">
										Tiene derecho a solicitarnos acceder a sus datos,
										corregirlos o eliminarlos, también puede solicitarnos
										limitar su tratamiento, oponerse a ello y a la portabilidad
										de sus datos, dirigiéndose a nuestra dirección postal o a
									</Trans>{" "}
									<a href="mailto:contacto@beginrestaurante.com">
										contacto@beginrestaurante.com
									</a>
								</td>
							</tr>
							<tr>
								<td>
									<Trans ns="dataProtection">Mas info</Trans>:
								</td>
								<td>
									<Trans ns="dataProtection">
										Dispone de más información en nuestra
									</Trans>{" "}
									<Link to="/legal/pollitica-privacidad">
										<Trans ns="dataProtection">Política de Privacidad</Trans>
									</Link>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</Layout>
	);
}

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
